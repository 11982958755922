import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { skin } from 'Utils'
import skins from './skins'

const CookiePolicy = props => {
  const {
    transitionDuration,
    showToggle,
    loading,
    showPanel,
    setShowPanel,
    setS8Cookie,
    cookies,
    className
  } = props
  const updateUiMsg = () => ({
    __html: `We use cookies to deliver a better experience. Click to change your settings.`
  })
  const defaultMsg = () => ({
    __html:
      'We use cookies to deliver a better experience. By clicking accept you agree to our cookie policy.'
  })
  const [showSettings, setShowSettings] = useState(false)
  const [value, setValue] = useState(cookies.s8cookiePolicy)
  const ACCEPTED = 'accepted'
  const REJECTED = 'rejected'

  function onChangeHandler(e) {
    const newValue = e.target.value
    setValue(newValue)
    setS8Cookie(newValue)
    setTimeout(() => {
      setShowPanel(false)
    }, 450)
  }

  return loading ? (
    <div className={`cookie-policy ${className}`}>
      <div className="cookie-policy__wrapper">
        <div className="cookie-policy__copy-wrapper">Loading...</div>
      </div>
    </div>
  ) : (
    <div className={`cookie-policy ${className}`}>
      <CSSTransition in={showToggle} unmountOnExit timeout={transitionDuration}>
        <button
          onClick={() => {
            setShowPanel(!showPanel)
          }}
          className={`cookie-policy__mobile-toggle`}
        >
          Cookie Policy
        </button>
      </CSSTransition>

      <div className="cookie-policy__wrapper">
        <div className="cookie-policy__section">
          <div className="cookie-policy__copy-wrapper">
            {typeof cookies.s8cookiePolicy !== 'undefined' ? (
              <p
                className="cookie-policy__copy"
                dangerouslySetInnerHTML={updateUiMsg()}
              />
            ) : (
              <p
                className="cookie-policy__copy"
                dangerouslySetInnerHTML={defaultMsg()}
              />
            )}
          </div>

          <button
            className="cookie-policy__cta accept-btn"
            value={ACCEPTED}
            onClick={e => onChangeHandler(e)}
          >
            Accept{' '}
          </button>

          <div
            className="cookie-policy__modify"
            onClick={() => setShowSettings(!showSettings)}
          >
            Modify your settings
          </div>
        </div>

        <CSSTransition
          in={showSettings}
          unmountOnExit
          timeout={transitionDuration}
        >
          <div className="cookie-policy__fields">
            <div className="RadioButton">
              <input
                onChange={onChangeHandler}
                type="radio"
                name="cookieOptions"
                id={ACCEPTED}
                value={ACCEPTED}
                checked={value === ACCEPTED}
              />
              <label htmlFor={ACCEPTED}>Accept</label>
            </div>
            <div className="RadioButton">
              <input
                onChange={onChangeHandler}
                type="radio"
                name="cookieOptions"
                id={REJECTED}
                value={REJECTED}
                checked={value === REJECTED}
              />
              <label htmlFor={REJECTED}>Reject</label>
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  )
}

CookiePolicy.propTypes = {
  transitionDuration: PropTypes.number,
  showToggle: PropTypes.bool,
  loading: PropTypes.bool,
  showPanel: PropTypes.bool,
  setShowPanel: PropTypes.func,
  setS8Cookie: PropTypes.func,
  cookies: PropTypes.any,
  className: PropTypes.string
}

export default skin(CookiePolicy, skins)
