import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { storeLocation } from 'Actions'

const useStoreLocation = props => {
  const { location, stateLocation } = props
  useEffect(() => {
    if (
      location.pathname !== stateLocation.pathname ||
      location.search !== stateLocation.search
    ) {
      props.storeLocation(props.location)
    }
  })

  return null
}

const mapStateToProps = state => ({
  stateLocation: state.UI.location
})

const mapDispatchToProps = dispatch => {
  return {
    storeLocation: bindActionCreators(storeLocation, dispatch),
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(useStoreLocation)
