import PropTypes from 'prop-types'
import React from 'react'
import config from 'Config'
import { SCREEN_TYPE } from 'Constants'

const Hero = props => {
  if (config.heroEnabled) {
    const imgSrc =
      props.screenType === SCREEN_TYPE.SMALL
        ? config.heroImage.small
        : config.heroImage.large
    return (
      <div className="hero-section">
        <img className="hero-section__bg-image" src={imgSrc} />
        <div className="hero-section__content">
          <h1 className="hero-section__h1">{config.heroTitle}</h1>
          <p>{config.heroCopy}</p>
        </div>
      </div>
    )
  } else {
    return null
  }
}

Hero.propTypes = {
  screenType: PropTypes.string
}

export default Hero
