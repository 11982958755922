import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import View from './component'
import {
  PageView,
  JourneyTracker,
  PageHelmet,
  TrackLinkDecrease,
  StoreLocation,
  AmplitudeEvent
} from 'Blocks'
import { skin } from 'Utils'
import { PAGE_CONTEXT as p } from 'Constants'
import skins from './skins'
import config from 'Config'

const pageName = p.HOME

const HomeContainer = props => (
  <Fragment>
    <PageHelmet
      htmlAttributes={{ class: 'homepage' }}
      pageName={pageName}
      description={config.helmet.defaultDescription}
    />
    <AmplitudeEvent eventType={config.amplitude.types.pageView.home} />
    <PageView url={props.location.pathname} />
    <TrackLinkDecrease url={props.location.pathname} />
    <StoreLocation location={props.location} />
    <JourneyTracker routeName={pageName} />
    <View {...props} />
  </Fragment>
)

HomeContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.any
  })
}

const mapStateToProps = state => ({
  screenType: get(state, 'UI.screenType', 'large')
})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default skin(
  connect(mapStateToProps, mapDispatchToProps)(HomeContainer),
  skins
)
