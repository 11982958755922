import { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { ACTION_TYPES } from 'Constants'
import config from 'Config'

const { JOURNEY_TRACK } = ACTION_TYPES

class JourneyTracker extends Component {
  static propTypes = {
    location: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.getJourneyExtraPayload = this.getJourneyExtraPayload.bind(this)
    this.buildJourneyParams = this.buildJourneyParams.bind(this)
    this.journeyTrack = this.journeyTrack.bind(this)
  }

  componentDidUpdate(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.journeyTrack(nextProps)
    }
  }

  componentDidMount() {
    this.journeyTrack()
  }

  getJourneyExtraPayload(props) {
    const { extra } = props

    if (extra) {
      return {
        topCategories: extra.topCategories.map(cat => ({
          name: cat.name,
          to: `${config.shopPath}/products/${cat.uuid}/${cat.slug}`
        })),
        subCategories: extra.subCategories.map(cat => ({
          name: cat.name,
          to: `${config.shopPath}/products/${cat.uuid}/${cat.slug}`
        }))
      }
    } else {
      return {}
    }
  }

  buildJourneyParams(params) {
    let journeyParams = {}
    if (params) {
      Object.keys(params).forEach(param => {
        if (param === 'categoryId') {
          journeyParams.uuid = params[param]
        } else {
          journeyParams[param] = params[param]
        }
      })
    }
    return journeyParams
  }

  journeyTrack(newProps) {
    const props = newProps || this.props
    let payload = {}

    payload.routeName = props.routeName

    if (props.params) {
      payload.params = this.buildJourneyParams(props.params)
    }
    payload.extra = this.getJourneyExtraPayload(props)
    if (props.name) {
      payload.name =
        get(props, 'params.subCategory', '') === 'search'
          ? 'Search'
          : props.name
    }
    if (props.location) {
      payload.to = `${props.location.pathname}${props.location.search}`
    }
    payload.hasStorePage = false

    props.dispatch({
      type: JOURNEY_TRACK,
      payload
    })
  }

  render() {
    return null
  }
}

export default JourneyTracker
