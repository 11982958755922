import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { gaReady, gtagReady } from 'Utils'

const pageView = ({ url, dimensions = {} }) => {
  const page = { page: url }
  const fields = { ...page, ...dimensions }
  if (gaReady()) {
    /* eslint-disable no-undef */
    ga('send', 'pageview', fields)
    ga(`${process.env.ANT_GA_CLIENT_TRACKER_NAME}.send`, 'pageview', fields)
    /* eslint-enable no-undef */
  }
  if (gtagReady()) {
    // eslint-disable-next-line no-undef
    gtag(
      'event',
      'Custom PageView',
      { tracker: `${process.env.ANT_GA_CLIENT_TRACKER_NAME}.send`, ...fields }
    )
  }
}

const PageView = ({ url, dimensions }) => {
  useEffect(() => {
    pageView({ url, dimensions })
  }, [url])
  return null
}

PageView.propTypes = {
  url: PropTypes.string,
  dimensions: PropTypes.any
}

export default PageView
