import { css } from '@emotion/core'
import { mediumUp, mediumOnly, largeUp, smallOnly } from 'Utils'

export default ({
  theme: {
    colors,
    typography,
    mixins,
    spacing,
    components: { productOverview }
  },
  theme
}) => {
  const { defaultSpacing = () => {}, defaultHeadingFont = () => {} } = mixins
  const { buyButton } = productOverview

  return css`
    .main-container {
      background-color: ${colors.bodyBackground};
      padding: ${spacing.vertical.medium} 0 0 0;
      @media ${smallOnly} {
        max-width: 100%;
      }
    }

    .cta {
      background-color: transparent;
      &:hover {
        background-color: black;
      }
    }

    .homepage-section {
      background-color: ${colors.tertiary};
      margin-bottom: ${spacing.vertical.large};
      padding: 1rem 1rem 5rem 1rem;
      @media ${mediumUp} {
        padding: 2rem 2rem 5rem 2rem;
      }
    }

    .hero-section,
    .list,
    .external-store-panels {
      ${defaultSpacing(theme)}
    }

    .hero-section {
      position: relative;
      margin: auto;
    }

    .categories-title {
      text-align: center;
      margin-bottom: ${spacing.vertical.medium};
      font-size: ${typography.sizes.larger};
    }

    .hero-section__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: ${colors.active};
      > * {
        margin-bottom: ${spacing.vertical.medium};
      }
      p {
        max-width: 80%;
        text-align: center;
        color: ${colors.complementary.primary};
        @media ${mediumOnly} {
          max-width: 70%;
        }
        @media ${largeUp} {
          max-width: 46%;
        }
      }

      h1 {
        margin-top: ${spacing.vertical.medium};
        line-height: 0.7;
        text-align: center;
        color: ${colors.complementary.primary};
      }
    }

    a:hover {
      text-decoration: none;
    }

    .hero-section__h1 {
      ${defaultHeadingFont(theme)}
      font-size: ${typography.sizes.xlarge};
    }

    .section {
      max-width: 1080px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      &.with-border {
        border-bottom: 1px solid ${colors.secondary};
        margin: 0 auto 2rem auto;
        padding-bottom: 2rem;
      }
    }

    .intro-title {
      font-weight: 400;
      line-height: 1.2;
      font-size: ${typography.sizes.larger};
      font-family: ${typography.body.fontFamily};
      color: ${colors.primary};
      @media ${mediumUp} {
        font-size: ${typography.sizes.xlarge};
      }
    }

    .category-panel {
      position: relative;
      display: block;
      text-align: center;
      vertical-align: middle;
      background-color: ${colors.complementary.primary};

      @media ${smallOnly} {
        margin: 0 auto;
        z-index: 9;
      }
    }

    .divider {
      height: 2px;
      background-color: ${colors.primary};
      margin: 1rem auto;
      width: 32px;
      @media ${mediumUp} {
        margin: 30px auto;
      }
    }

    .category-panel__img-container {
      @media ${smallOnly} {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin: 20px auto 0 auto;
        position: relative;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
      @media ${mediumUp} {
        .category-panel__img-container {
          height: fit-content;
          width: 100%;
        }
      }
    }

    .category-panel__title {
      font-size: ${typography.sizes.larger};
      @media ${mediumUp} {
        font-size: ${typography.sizes.larger};
        background-color: ${colors.complementary.primary};
      }
    }

    .category-panel__content {
      text-align: center;
      vertical-align: middle;
      padding: 20px;
      margin: 0 auto;
      background-color: ${colors.complementary.primary};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media ${mediumUp} {
        padding: 30px 0;
        margin: 0 auto;
        > * {
          margin: 0 40px;
          max-width: 300px;
        }
      }
    }

    .category-panel__description {
      line-height: 1.3;
    }

    @media ${mediumUp} {
      .first-row {
        display: flex;
        justify-content: center;
        padding: 8px;
        .category-panel {
          display: flex;
        }
        .category-panel__img-container {
          width: 70%;
        }
        .category-panel__content {
          width: 30%;
        }

        &.reverse {
          .category-panel {
            flex-direction: row-reverse;
          }
        }
      }

      .second-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .category-panel {
          position: relative;
          z-index: 2;
          margin: 8px;
          background-color: ${colors.complementary.primary};
        }
      }
    }
    .browse-button {
      &.yellow-btn {
        position: absolute;
        bottom: 20px;
        @media ${smallOnly} {
          bottom: 15px;
        }
        left: calc(50% - 112px);
        color: ${buyButton.color};
        background-color: ${buyButton.backgroundColor};
        border: ${buyButton.border.style};
        border-color: ${buyButton.border.color};
        border-radius: ${buyButton.border.radius};
        &:hover {
          cursor: pointer;
          color: ${buyButton.hover.color};
          background-color: ${buyButton.hover.backgroundColor};
          border-color: ${buyButton.hover.borderColor};
        }
      }
    }

    .hero-section {
      .browse-button {
        margin-top: 1rem;
        @media ${mediumUp} {
          margin-top: 30px;
        }
      }
    }
  `
}
