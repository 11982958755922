import React from 'react'
import config from 'Config'

class AmplitudeEvent extends React.Component {
  componentDidUpdate(prevProps) {
    const isWindowAmplitude =
      typeof window !== 'undefined' && window.amplitude !== 'undefined'
    if (config.amplitude.enabled && isWindowAmplitude) {
      if (
        this.props.eventType === config.amplitude.types.pageView.productList
      ) {
        const newPath = this.props.eventProps['Category Path']
        const oldPath = prevProps.eventProps['Category Path']

        if (newPath !== oldPath) {
          /* eslint-disable no-undef */
          amplitude
            .getInstance()
            .logEvent(this.props.eventType, this.props.eventProps)
          /* eslint-enable no-undef */
        }
      }

      if (this.props.eventType === config.amplitude.types.pageView.product) {
        if (
          this.props.eventProps['Product UUID'] !==
          prevProps.eventProps['Product UUID']
        ) {
          /* eslint-disable no-undef */
          window.amplitude
            .getInstance()
            .logEvent(this.props.eventType, this.props.eventProps)
          /* eslint-enable no-undef */
        }
      }
    }
  }

  componentDidMount() {
    const isWindowAmplitude =
      typeof window !== 'undefined' && window.amplitude !== 'undefined'
    if (config.amplitude.enabled && isWindowAmplitude) {
      if (
        this.props.eventType !== config.amplitude.types.pageView.productList
      ) {
        /* eslint-disable no-undef */
        amplitude
          .getInstance()
          .logEvent(this.props.eventType, this.props.eventProps)
        /* eslint-enable no-undef */
      }
    }
  }

  render() {
    return null
  }
}

export default AmplitudeEvent
