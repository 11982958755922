import React, { useState, useEffect } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Component from './component'
import { useCookies } from 'react-cookie'
import { gaReady, gtagReady } from 'Utils'

const {
  ANT_GA_CODE,
  ANT_GA_CLIENT_CODE,
  ANT_GA_CLIENT_TRACKER_NAME,
  ANT_GA4_CODE,
} = process.env

const CookiePolicyContainer = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['s8cookiePolicy'])
  const [loading, setLoading] = useState(true)
  const [showPanel, setShowPanel] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const transitionDuration = 350

  let showToggle = typeof cookies.s8cookiePolicy !== 'undefined' || scrolled

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (typeof cookies.s8cookiePolicy !== 'undefined') return
      const isTop = currPos.y === 0
      setShowPanel(isTop)
      setScrolled(!isTop)
    },
    [showPanel],
    '',
    true,
    400
  )

  function disableGA() {
    // TODO expand with gtag
    if (gaReady()) {
      // eslint-disable-next-line
      window[`ga-disable-${ANT_GA_CODE}`] = true
      window[`ga-disable-${ANT_GA_CLIENT_CODE}`] = true
      // eslint-disable-next-line
    }

    if (gtagReady()) {
      // eslint-disable-next-line
      window[`ga-disable-${ANT_GA4_CODE}`] = true
      // eslint-disable-next-line
    }
  }

  const setS8Cookie = value => {
    let maxAge = '31536000' // 365 days
    if (value === 'rejected') {
      disableGA()
      removeCookie('_ga', { path: '/' })
      removeCookie('_gat', { path: '/' })
      removeCookie('_gid', { path: '/' })
      removeCookie(`_ga${ANT_GA_CLIENT_TRACKER_NAME}`, { path: '/' })
      removeCookie(`_gat_${ANT_GA_CLIENT_TRACKER_NAME}`, { path: '/' })
      removeCookie(`_gid${ANT_GA_CLIENT_TRACKER_NAME}`, { path: '/' })
      maxAge = '2592000' // 30 days
    }
    setCookie('s8cookiePolicy', value, {
      path: '/',
      maxAge
    })
  }

  useEffect(() => {
    if (typeof cookies.s8cookiePolicy === 'undefined') {
      setShowPanel(true)
    }
    setLoading(false)
  }, [])

  return (
    <Component
      {...{
        setS8Cookie,
        cookies,
        loading,
        showPanel,
        setShowPanel,
        scrolled,
        transitionDuration,
        showToggle
      }}
    />
  )
}

export default CookiePolicyContainer
