import { css } from '@emotion/core'
import { smallOnly, largeUp } from 'Utils'
import theme from 'Theme'

const { zIndex, mixins } = theme

const checkedcolor = 'rgb(51, 122, 183)'
const bordercolor = 'rgba(255, 255, 255, 0.54)'
const radiosize = 20
const checkedsize = 10

export default props => {
  const transitionProps = `${props.transitionDuration}ms ease`
  return css`
    *,
    *:before,
    *:after {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
    }
    font-family: Helvetica, Arial, sans-serif;
    font-size: 15px;
    width: 100%;
    min-height: 78px;
    position: fixed;
    z-index: ${zIndex.cookiePolicy};
    bottom: 0;
    background-color: #000;
    padding: 2rem;
    transition: transform ${transitionProps};
    transform: ${props.showPanel ? `translateY(0)` : `translateY(100%)`};

    .cookie-policy__wrapper {
      width: 95%;
      max-width: 1100px;
      margin: 0 auto;
      position: relative;
    }

    .cookie-policy__section {
      @media ${largeUp} {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .cookie-policy__mobile-toggle {
      position: absolute;
      top: -3rem;
      left: 2vw;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 2rem;
      background-color: black;
      color: white;
      height: 3rem;
      transition: opacity ${transitionProps};

      &.enter {
        opacity: 0;
      }
      &.enter-active {
        opacity: 1;
      }
      &.enter-done {
        opacity: 1;
      }
      &.exit {
        opacity: 1;
      }
      &.exit-active {
        opacity: 0;
      }
    }

    .cookie-policy__copy-wrapper {
      @media ${largeUp} {
        width: 50%;
        padding-right: 2rem;
      }
    }

    .cookie-policy__copy {
      color: white;
      line-height: 1.2;
      margin-bottom: 1.5rem;
      @media ${largeUp} {
        margin-bottom: 0;
      }
    }

    .cookie-policy__cta {
      ${mixins.defaultButtonPadding()}
      border-radius: 0;
      background-color: #fff;
      color: #000;
      appearance: none;
      border: none;
      @media ${largeUp} {
        min-width: 200px;
      }
      &:hover {
        background-color: #e6e6e6;
      }
      &:first-of-type {
        margin-right: 1rem;
      }

      &.accept-btn {
        width: 100%;
        @media ${largeUp} {
          max-width: 250px;
        }
      }
    }

    .cookie-policy__modify {
      color: white;
      margin: 1.5rem 0;

      @media ${largeUp} {
        margin: 0 30px 0 0;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .cookie-policy__fields {
      display: flex;
      transition: opacity, height, ${transitionProps};

      &.enter {
        opacity: 0;
        height: 0;
      }
      &.enter-active {
        opacity: 1;
        height: calc(2rem + ${radiosize}px);
      }
      &.enter-done {
        opacity: 1;
        height: calc(2rem + ${radiosize}px);
      }
      &.exit {
        opacity: 1;
        height: calc(2rem + ${radiosize}px);
      }
      &.exit-active {
        opacity: 0;
        height: 0;
      }

      @media ${largeUp} {
        justify-content: flex-end;
      }
    }

    .RadioButton {
      margin: 1rem 0;
      position: relative;
      min-height: ${radiosize}px;
      width: 50%;
      @media ${largeUp} {
        width: auto;
      }

      input[type='radio'] {
        display: none;

        &:checked + label:before {
          border-color: ${checkedcolor};
        }

        &:checked + label:after {
          transform: scale(1);
        }
      }

      label {
        color: white;
        display: inline-block;
        height: ${radiosize}px;
        position: relative;
        padding: 0 ${radiosize + 10}px;
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;

        &:before,
        &:after {
          position: absolute;
          content: '';
          border-radius: 50%;
          transition: all ${transitionProps};
          transition-property: transform, border-color;
        }

        &:before {
          left: 0;
          top: 0;
          width: ${radiosize}px;
          height: ${radiosize}px;
          border: 2px solid ${bordercolor};
        }

        &:after {
          top: ${radiosize / 2 - checkedsize / 2};
          left: ${radiosize / 2 - checkedsize / 2};
          width: ${checkedsize}px;
          height: ${checkedsize}px;
          transform: scale(0);
          background: ${checkedcolor};
        }
      }
    }
  `
}
