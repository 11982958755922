import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { PAGE_CONTEXT as p } from 'Constants'
import { Hero } from '../components'
import { path, withGAEvent } from 'Utils'
import { Icon, LazyLoadImage, Link } from 'Blocks'
import config, { gaSettings } from 'Config'
import CookiePolicy from '../../CookiePolicy'

const {
  homePage: { categorylinks }
} = gaSettings
const browseId = process.env.ANT_BROWSE_ID
const {
  heroTitle,
  heroCopy,
  home: { panels }
} = config

const BrowseButton = ({ compareLinkLabel = 'Load more', className = '' }) => {
  const browseUrl = path(p.PRODUCT_LIST, {
    uuid: browseId,
    subCategory: 'browse'
  })
  return (
    <Link className={`cta browse-button ${className}`} to={browseUrl}>
      {compareLinkLabel}
    </Link>
  )
}

BrowseButton.propTypes = {
  className: PropTypes.string,
  compareLinkLabel: PropTypes.string
}

const CategoryCard = props => {
  const { uuid, name, description, image } = props.category
  const imageSrc = props.screenType === 'small' ? image.small : image.large
  const imageWidth = props.screenType === 'small' ? 400 : 'auto'

  const categoryLink = path(p.PRODUCT_LIST, {
    uuid,
    subCategory: name
  })
  const GALink = withGAEvent(Link, {
    category: categorylinks.category,
    action: categorylinks.action,
    label: `linkName=${name} - destinationUrl=${categoryLink} - Category Panel Link`,
    additionalProps: {
      linkName: name,
      destinationUrl: categoryLink,
    },
  })

  return (
    <GALink data-cy="category" className="category-panel" to={categoryLink}>
      <div className="category-panel__img-container">
        <LazyLoadImage useOriginal image={imageSrc} width={imageWidth} />
      </div>
      <div className="category-panel__content">
        <div>
          <h2 className="category-panel__title intro-title">{name}</h2>
          <div className="divider"></div>
          <div className="category-panel__description desc">{description}</div>
        </div>
      </div>
    </GALink>
  )
}

CategoryCard.propTypes = {
  category: PropTypes.shape({
    uuid: PropTypes.any,
    name: PropTypes.any,
    description: PropTypes.any,
    image: PropTypes.shape({
      large: PropTypes.any,
      small: PropTypes.any
    })
  }),
  screenType: PropTypes.string
}

class HomePage extends Component {
  render() {
    const { screenType, className } = this.props
    return (
      <div className={`${className} main-container`}>
        <Hero
          title={heroTitle}
          description={heroCopy}
          screenType={screenType}
        />

        <div className="homepage-section">
          {screenType !== 'small' ? (
            <Fragment>
              <div className="section with-border">
                <div className="first-row reverse">
                  {panels.slice(0, 1).map((category, index) => {
                    return (
                      <CategoryCard
                        screenType={screenType}
                        position={index}
                        key={`panel-${category.uuid}`}
                        {...{ category }}
                      />
                    )
                  })}
                </div>
                <div className="second-row">
                  {panels.slice(1, 3).map((category, index) => {
                    return (
                      <CategoryCard
                        screenType={screenType}
                        position={index}
                        key={`panel-${category.uuid}`}
                        {...{ category }}
                      />
                    )
                  })}
                </div>
              </div>

              <div className="section">
                <div className="first-row">
                  {panels.slice(3, 4).map((category, index) => {
                    return (
                      <CategoryCard
                        screenType={screenType}
                        position={index}
                        key={`panel-${category.uuid}`}
                        {...{ category }}
                      />
                    )
                  })}
                </div>
                <div className="second-row">
                  {panels.slice(4, 6).map((category, index) => {
                    return (
                      <CategoryCard
                        screenType={screenType}
                        position={index}
                        key={`panel-${category.uuid}`}
                        {...{ category }}
                      />
                    )
                  })}
                </div>
                <div className="first-row reverse">
                  {panels.slice(6, 7).map((category, index) => {
                    return (
                      <CategoryCard
                        screenType={screenType}
                        position={index}
                        key={`panel-${category.uuid}`}
                        {...{ category }}
                      />
                    )
                  })}
                </div>
              </div>
            </Fragment>
          ) : (
            panels.slice(0, 7).map(category => {
              return (
                <CategoryCard
                  screenType={screenType}
                  key={`panel-${category.uuid}`}
                  {...{ category }}
                />
              )
            })
          )}
        </div>
        <BrowseButton className="yellow-btn" />
        <CookiePolicy />
      </div>
    )
  }
}

HomePage.defaultProps = {
  className: ''
}

HomePage.propTypes = {
  className: PropTypes.string,
  screenType: PropTypes.string
}

export default HomePage
