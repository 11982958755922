import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { trackLinkDecrease } from 'Actions'

class TrackLinkDecrease extends Component {
  static propTypes = {
    trackLinkDecrease: PropTypes.func,
    url: PropTypes.string
  }

  componentDidMount() {
    this.props.trackLinkDecrease()
  }

  componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.props.trackLinkDecrease()
    }
  }

  render() {
    return null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    trackLinkDecrease: bindActionCreators(trackLinkDecrease, dispatch),
    dispatch
  }
}

export default connect(null, mapDispatchToProps)(TrackLinkDecrease)
